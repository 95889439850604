/* eslint-disable react/display-name */
__webpack_public_path__ = `${window.chunkspath || document.documentElement.dataset.publicpath}`;
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import '@scripts/static';
import './scss/main.scss';
import '@utils/i18n';
import { registerComponent, initReactApps, registerDefaultComponents } from '@common/loader';
import * as Sentry from '@sentry/react';
// import SASS
import { Integrations } from '@sentry/tracing';
import analytics from '@utils/analytics';
import webpsupport from '@utils/webpsupport';
import avifsupport from '@utils/avifsupport';
import React from 'react';
import cookie from 'react-cookies';
import BreakpointerService from '@stone/scripts/services/breakpointerService';
import { ResolutionHandler } from '@stone/scripts/utilities/resolutionHandler';
ResolutionHandler();
new BreakpointerService();
//istance of what workspace is
window.brand = 'stone';
window.brandId = 'ST';
// instanzio la gestione del gtm su window
window.analytics = analytics;
if ('undefined' === typeof window._etmc) {
    window._etmc = []; // avoid errors for late instantiation of marketing cloud collect code
}
// Map Enviroment as described in OE-13892
let sentryEnvironment = 'moncweb-dev';
if (window.environment === 'production') {
    sentryEnvironment = 'moncweb-prod';
}
else if (window.environment === 'staging') {
    sentryEnvironment = 'moncweb-stag';
}
// development, sandbox
// prevent sentry on dev sandboxes
if (window.location.hostname.indexOf('dev') === -1 && !window.disableAnalytics) {
    if (window.sentryDsn && window.sentrySampleRate) {
        Sentry.init({
            dsn: window.sentryDsn,
            environment: (window.ssr ? 'ssr-' : '') + sentryEnvironment,
            release: window.release,
            autoSessionTracking: true,
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: window.sentrySampleRate,
            // This enables automatic instrumentation (highly recommended), but is not
            // necessary for purely manual usage
            integrations: [new Integrations.BrowserTracing()],
        });
    }
    // Sentry.init({
    //   dsn: 'https://d4fd99cefa4741af819754d0a79d3d82@o1202846.ingest.sentry.io/6568583',
    //   environment: (window.ssr ? 'ssr-' : '') + window.location.hostname.substr(0, window.location.hostname.indexOf('.')),
    //   release: window.release,
    //   autoSessionTracking: true,
    //   // We recommend adjusting this value in production, or using tracesSampler
    //   // for finer control
    //   tracesSampleRate: 0.9,
    //   // This enables automatic instrumentation (highly recommended), but is not
    //   // necessary for purely manual usage
    //   integrations: [new Integrations.BrowserTracing()],
    // });
}
if (window.cookiePolicy === 'OneTrust') {
    const lockBody = () => {
        try {
            if (window.OneTrust && window.OneTrust.IsAlertBoxClosed && !window.OneTrust.IsAlertBoxClosed()) {
                document.body.classList.add('no-overflow-container');
            }
        }
        catch (error) {
            console.log('ERROR :' + error);
        }
    };
    lockBody();
    window.addEventListener('onetrust.ready', lockBody);
    window.addEventListener('onetrust.changed', function () {
        document.body.classList.remove('no-overflow-container');
        const encodedURI = encodeURIComponent(window.OnetrustActiveGroups);
        fetch(window.homeurl + '/consent/onetrust?optanonag=' + encodedURI)
            .then((res) => res.json())
            .then((response) => {
            console.log(response);
            cookie.save('OneTrustChoices', JSON.stringify(response), { path: '/' });
            window.dispatchEvent(new CustomEvent('OneTrust:changed', { detail: response }));
        })
            .catch((err) => {
            if (typeof err == 'string') {
                console.log(JSON.parse(err));
            }
            else {
                console.error('Consent url not found');
            }
        });
    });
}
// per gestione transizione PDP/PLP
if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}
webpsupport.then((supported) => {
    window.webpsupported = supported;
});
avifsupport.then((supported) => {
    window.avifsupported = supported;
});
const registerStoneComponents = () => {
    const TestStone = React.lazy(() => import(/* webpackChunkName: "component-test-stone" */ './components/test/TestStone'));
    registerComponent('test-stone', (attrs) => React.createElement(TestStone, Object.assign({}, attrs)));
    const Menu = React.lazy(() => import(/* webpackChunkName: "component-menu" */ '@stone/components/menu/Menu'));
    registerComponent('menu-app', (attrs) => React.createElement(Menu, Object.assign({}, attrs)));
    const Footer = React.lazy(() => import(/* webpackChunkName: "component-footer" */ '@stone/components/footer/Footer'));
    registerComponent('footer-app', (attrs) => React.createElement(Footer, Object.assign({}, attrs)));
    const ProductTileRail = React.lazy(() => import(/* webpackChunkName: "component-tilerail" */ '@stone/components/product/ProductTilesRail'));
    registerComponent('tilerail-app', (attrs) => React.createElement(ProductTileRail, Object.assign({}, attrs)));
    const LoginModal = React.lazy(() => import(/* webpackChunkName: "component-login-modal" */ '@stone/components/login/LoginModal'));
    registerComponent('login-modal', (attrs) => React.createElement(LoginModal, Object.assign({}, attrs)));
    const CheckoitConfirmLoader = React.lazy(() => import(/* webpackChunkName: "checkout-confirm-page" */ '@stone/components/checkout/CheckoutConfirmationLoader'));
    registerComponent('checkout-confirm-page', (attrs) => React.createElement(CheckoitConfirmLoader, Object.assign({}, attrs)));
    const Wishlist = React.lazy(() => import(/* webpackChunkName: "component-wishlist" */ '@stone/components/wishlist/Wishlist'));
    registerComponent('wishlist-app', (attrs) => React.createElement(Wishlist, Object.assign({}, attrs)));
    const ProductPage = React.lazy(() => import(/* webpackChunkName: "component-productpage" */ '@stone/components/product/ProductPage'));
    registerComponent('pdp-app', (attrs) => React.createElement(ProductPage, Object.assign({}, attrs)));
    const StoryListing = React.lazy(() => import(/* webpackChunkName: "component-storylisting" */ '@stone/components/storylisting/StoryListingLoader'));
    registerComponent('story-listing-app', (attrs) => React.createElement(StoryListing, Object.assign({}, attrs)));
    const ContentParallax = React.lazy(() => import(/* webpackChunkName: "component-content-parallax" */ '@stone/components/common/ContentParallax'));
    registerComponent('content-parallax', (attrs) => React.createElement(ContentParallax, Object.assign({}, attrs)));
    // const Details = React.lazy(() => import(/* webpackChunkName: "component-storylisting" */ '@stone/components/details/Details'));
    // registerComponent('component-details', (attrs: any) => <Details {...attrs} />);
    const Details = React.lazy(() => import(/* webpackChunkName: "component-storylisting" */ '@stone/components/details/DetailsLoader'));
    registerComponent('component-details-extended', (attrs) => React.createElement(Details, Object.assign({}, attrs)));
    const Bag = React.lazy(() => import(/* webpackChunkName: "component-bag" */ '@stone/components/bag/BagLoader'));
    registerComponent('cart-app', (attrs) => React.createElement(Bag, Object.assign({}, attrs)));
    // const MiniBag = React.lazy(() => import(/* webpackChunkName: "component-minicart" */ '@stone/components/minibag/MiniBagLoader'));
    // registerComponent('minibag-app', (attrs: any) => <MiniBag {...attrs} />); // for stone is inside the header
    const SearchApp = React.lazy(() => import(/* webpackChunkName: "component-search" */ '@stone/components/search/SearchApp'));
    registerComponent('search-app', (attrs) => React.createElement(SearchApp, Object.assign({}, attrs)));
    const SearchNoResults = React.lazy(() => import(/* webpackChunkName: "component-search" */ '@stone/components/search/SearchNoResults'));
    registerComponent('search-no-results', (attrs) => React.createElement(SearchNoResults, Object.assign({}, attrs)));
    const AccountDashboard = React.lazy(() => import(/* webpackChunkName: "component-account-dashboard" */ '@stone/components/account/AccountDashboard'));
    registerComponent('myaccount-dashboard', (attrs) => React.createElement(AccountDashboard, Object.assign({}, attrs)));
    const MyAccountProfile = React.lazy(() => import(/* webpackChunkName: "component-myaccountprofile" */ '@stone/components/account/MyAccountProfileLoader'));
    registerComponent('myaccount-profile-app', (attrs) => React.createElement(MyAccountProfile, Object.assign({}, attrs)));
    const MyAccountPreferences = React.lazy(() => import(/* webpackChunkName: "component-myaccountpreferences" */ '@stone/components/account/MyAccountPreferencesLoader'));
    registerComponent('myaccount-preferences-app', (attrs) => React.createElement(MyAccountPreferences, Object.assign({}, attrs)));
    const RegisterPage = React.lazy(() => import(/* webpackChunkName: "component-register" */ '@stone/components/account/RegisterPage'));
    registerComponent('register-app', (attrs) => React.createElement(RegisterPage, Object.assign({}, attrs)));
    const Login = React.lazy(() => import(/* webpackChunkName: "component-login" */ '@stone/components/login/LoginOrRegisterPage'));
    registerComponent('login-app', (attrs) => React.createElement(Login, Object.assign({}, attrs)));
    const AccountForgotPassword = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step1" */ '@stone/components/account/AccountForgotPasswordLoader'));
    registerComponent('forgotpassword-app', (attrs) => React.createElement(AccountForgotPassword, Object.assign({}, attrs)));
    const AccountOrdersHistory = React.lazy(() => import(/* webpackChunkName: "component-myaccountordershistory" */ '@stone/components/account/AccountOrdersHistoryLoader'));
    registerComponent('myaccount-orders-app', (attrs) => React.createElement(AccountOrdersHistory, Object.assign({}, attrs)));
    const AccountOrdersAfterCare = React.lazy(() => import(/* webpackChunkName: "component-myaccountordersaftercare" */ '@stone/components/account/AccountAfterCareLoader'));
    registerComponent('myaccount-aftercare-app', (attrs) => React.createElement(AccountOrdersAfterCare, Object.assign({}, attrs)));
    const ManageAppointmentPage = React.lazy(() => import(/* webpackChunkName: "component-clientservice-manageappointment" */ '@stone/components/appointments/ManageAppointmentPage'));
    registerComponent('clientservice-manageappointment', (attrs) => React.createElement(ManageAppointmentPage, Object.assign({}, attrs)));
    const AppointmentsPage = React.lazy(() => import(/* webpackChunkName: "component-appointment-page" */ '@stone/components/appointments/AppointmentsPage'));
    registerComponent('appointmentlist', (attrs) => React.createElement(AppointmentsPage, Object.assign({}, attrs)));
    const BookAnAppointmentPage = React.lazy(() => import(/* webpackChunkName: "component-clientservice-bookanappointment" */ '@stone/components/appointments/BookAnAppointmentPage'));
    registerComponent('clientservice-bookanappointment', (attrs) => React.createElement(BookAnAppointmentPage, Object.assign({}, attrs)));
    const AccountOrdersReturns = React.lazy(() => import(/* webpackChunkName: "component-myaccountorderreturns" */ '@stone/components/account/AccountOrdersReturnsLoader'));
    registerComponent('myaccount-orders-returns', (attrs) => React.createElement(AccountOrdersReturns, Object.assign({}, attrs)));
    //TODO Customer Service "editorial" FORMS remove Moncler reference and move it on Stone
    const ReturnFormPageLoader = React.lazy(() => import(/* webpackChunkName: "component-clientservicereturns" */ '@stone/components/clientService/ReturnFormPageLoader'));
    registerComponent('client-service-returns', (attrs) => React.createElement(ReturnFormPageLoader, Object.assign({}, attrs)));
    const ClientServiceAftercare = React.lazy(() => import(/* webpackChunkName: "component-clientserviceaftercare" */ '@stone/components/aftercare/AftercareLoader'));
    registerComponent('client-service-aftercare', (attrs) => React.createElement(ClientServiceAftercare, Object.assign({}, attrs)));
    const ClientServiceExchange = React.lazy(() => import(/* webpackChunkName: "component-clientserviceexchange" */ '@stone/components/clientService/ClientServiceExchangeLoader'));
    registerComponent('client-service-exchange', (attrs) => React.createElement(ClientServiceExchange, Object.assign({}, attrs)));
    const ClientServiceUnshoppableReturn = React.lazy(() => import(/* webpackChunkName: "component-exchangeinboutique" */ '@stone/components/clientService/ClientServiceUnshoppableReturn'));
    registerComponent('client-service-unshoppable-return', (attrs) => React.createElement(ClientServiceUnshoppableReturn, Object.assign({}, attrs)));
    const NewReturnPageLoader = React.lazy(() => import(/* webpackChunkName: "component-newreturn" */ '@stone/components/returns/NewReturnPageLoader'));
    registerComponent('newreturn', (attrs) => React.createElement(NewReturnPageLoader, Object.assign({}, attrs)));
    const NewExchangePageLoader = React.lazy(() => import(/* webpackChunkName: "component-newexchange" */ '@stone/components/exchange/NewExchangePageLoader'));
    registerComponent('newexchange', (attrs) => React.createElement(NewExchangePageLoader, Object.assign({}, attrs)));
    const GetInTouch = React.lazy(() => import(/* webpackChunkName: "component-getintouch" */ '@stone/components/clientService/GetInTouch'));
    registerComponent('getintouch', (attrs) => React.createElement(GetInTouch, Object.assign({}, attrs)));
    const ClientServiceTracking = React.lazy(() => import(/* webpackChunkName: "component-clientservicetracking" */ '@stone/components/clientService/ClientServiceTrackingLoader'));
    registerComponent('client-service-tracking', (attrs) => React.createElement(ClientServiceTracking, Object.assign({}, attrs)));
    const OrderDetailPageLoader = React.lazy(() => import(/* webpackChunkName: "component-orderdetail" */ '@stone/components/order/OrderDetailPageLoader'));
    registerComponent('component-orderdetail', (attrs) => React.createElement(OrderDetailPageLoader, Object.assign({}, attrs)));
    const ReturnDetailPageLoader = React.lazy(() => import(/* webpackChunkName: "component-returndetail" */ '@stone/components/returns/ReturnDetailPageLoader'));
    registerComponent('returndetail', (attrs) => React.createElement(ReturnDetailPageLoader, Object.assign({}, attrs)));
    const AftercareDetailPageLoader = React.lazy(() => import(/* webpackChunkName: "component-aftercaredetailhistory" */ '@stone/components/aftercare/AftercareDetailPageLoader'));
    registerComponent('component-aftercaredetail', (attrs) => React.createElement(AftercareDetailPageLoader, Object.assign({}, attrs)));
    const ClientServiceContactUs = React.lazy(() => import(/* webpackChunkName: "component-clientservicecontactus" */ '@stone/components/clientService/ClientServiceContactUsLoader'));
    registerComponent('client-service-contactus', (attrs) => React.createElement(ClientServiceContactUs, Object.assign({}, attrs)));
    const FaqSearchInput = React.lazy(() => import(/* webpackChunkName: "component-faqsearchinput" */ '@stone/components/clientService/FaqSearchInput'));
    registerComponent('faq-search-input', (attrs) => React.createElement(FaqSearchInput, Object.assign({}, attrs)));
    const FaqResults = React.lazy(() => import(/* webpackChunkName: "component-faqsearch" */ '@stone/components/clientService/FaqResults'));
    registerComponent('faq-results', (attrs) => React.createElement(FaqResults, Object.assign({}, attrs)));
    const ComponentStepsLoader = React.lazy(() => import(/* webpackChunkName: "component-steps" */ '@stone/components/clientService/ClientServiceSteps'));
    registerComponent('component-steps', (attrs) => React.createElement(ComponentStepsLoader, Object.assign({}, attrs)));
    const CorporateLocationsLoader = React.lazy(() => import(/* webpackChunkName: "corporate-locations" */ '@stone/components/corporate/CorporateLocationsLoader'));
    registerComponent('corporate-locations', (attrs) => React.createElement(CorporateLocationsLoader, Object.assign({}, attrs)));
    const FocusOn = React.lazy(() => import(/* webpackChunkName: "component-focuson" */ '@stone/components/common/FocusOn'));
    registerComponent('component-focus-on', (attrs) => React.createElement(FocusOn, Object.assign({}, attrs)));
    const CarouselHero = React.lazy(() => import(/* webpackChunkName: "component-carouselhero" */ '@stone/components/common/CarouselHero'));
    registerComponent('component-carousel-hero', (attrs) => React.createElement(CarouselHero, Object.assign({}, attrs)));
    const EventsListing = React.lazy(() => import(/* webpackChunkName: "component-eventslisting" */ '@stone/components/common/EventsListing'));
    registerComponent('component-events-listing', (attrs) => React.createElement(EventsListing, Object.assign({}, attrs)));
    const Catalog = React.lazy(() => import(/* webpackChunkName: "component-catalog" */ './components/listing/Catalog'));
    registerComponent('catalog-app', (attrs) => React.createElement(Catalog, Object.assign({}, attrs)));
    const Authenticity = React.lazy(() => import(/* webpackChunkName: "component-catalog" */ './components/authenticity/authenticityForm'));
    registerComponent('authenticity-form', (attrs) => React.createElement(Authenticity, Object.assign({}, attrs)));
    const ShopByCategory = React.lazy(() => import(/* webpackChunkName: "component-shop-by-category" */ './components/shopByCategory/ShopByCategory'));
    registerComponent('shop-by-category', (attrs) => React.createElement(ShopByCategory, Object.assign({}, attrs)));
    const HeroProducts = React.lazy(() => import(/* webpackChunkName: "component-hero-products" */ './components/heroProducts/HeroProducts'));
    registerComponent('hero-products', (attrs) => React.createElement(HeroProducts, Object.assign({}, attrs)));
    const IconGallery = React.lazy(() => import(/* webpackChunkName: "component-icon-gallery" */ './components/iconGallery/IconGallery'));
    registerComponent('icon-gallery', (attrs) => React.createElement(IconGallery, Object.assign({}, attrs)));
    const Page404 = React.lazy(() => import(/* webpackChunkName: "component-page-404" */ './components/common/Page404'));
    registerComponent('page-404', (attrs) => React.createElement(Page404, Object.assign({}, attrs)));
    const Features = React.lazy(() => import(/* webpackChunkName: "component-features" */ './components/features/Features'));
    registerComponent('features', (attrs) => React.createElement(Features, Object.assign({}, attrs)));
    const FlpHero = React.lazy(() => import(/* webpackChunkName: "component-flp-hero" */ './components/flpHero/FlpHero'));
    registerComponent('flp-hero', (attrs) => React.createElement(FlpHero, Object.assign({}, attrs)));
    const HubHero = React.lazy(() => import(/* webpackChunkName: "component-hub-hero" */ './components/hubHero/HubHero'));
    registerComponent('hub-hero', (attrs) => React.createElement(HubHero, Object.assign({}, attrs)));
    const Editorial = React.lazy(() => import(/* webpackChunkName: "component-editorial" */ './components/editorial/Editorial'));
    registerComponent('component-editorial', (attrs) => React.createElement(Editorial, Object.assign({}, attrs)));
    const EditorialVideoWrapper = React.lazy(() => import(/* webpackChunkName: "component-editorial-video" */ './components/editorial/EditorialVideoWrapper'));
    registerComponent('component-editorial-video', (attrs) => React.createElement(EditorialVideoWrapper, Object.assign({}, attrs)));
    const EditorialHero = React.lazy(() => import(/* webpackChunkName: "editorial-hero" */ './components/hero/EditorialHero'));
    registerComponent('editorial-hero', (attrs) => React.createElement(EditorialHero, Object.assign({}, attrs)));
    const ImageModule = React.lazy(() => import(/* webpackChunkName: "image-module" */ './components/imageModule/ImageModule'));
    registerComponent('image-module', (attrs) => React.createElement(ImageModule, Object.assign({}, attrs)));
    const AudioLoader = React.lazy(() => import(/* webpackChunkName: "component-audio" */ './components/audio/ComponentAudioLoader'));
    registerComponent('component-audio', (attrs) => React.createElement(AudioLoader, Object.assign({}, attrs)));
    const InteractiveImageLoader = React.lazy(() => import(/* webpackChunkName: "component-audio" */ './components/interactiveImage/ComponentInteractiveImageLoader'));
    registerComponent('component-interactive-image', (attrs) => React.createElement(InteractiveImageLoader, Object.assign({}, attrs)));
    const ArchiveGrid = React.lazy(() => import(/* webpackChunkName: "component-archive-grid" */ './components/archive/ArchiveGrid'));
    registerComponent('archive-grid', (attrs) => React.createElement(ArchiveGrid, Object.assign({}, attrs)));
    const ProgressiveRevealLoader = React.lazy(() => import(/* webpackChunkName: "component-progressive-reveal" */ './components/progressiveReveal/ProgressiveRevealLoader'));
    registerComponent('progressive-reveal', (attrs) => React.createElement(ProgressiveRevealLoader, Object.assign({}, attrs)));
    const Gallery = React.lazy(() => import(/* webpackChunkName: "component-gallery" */ './components/gallery/Gallery'));
    registerComponent('component-gallery', (attrs) => React.createElement(Gallery, Object.assign({}, attrs)));
    const LatestCarouselEditorial = React.lazy(() => import(/* webpackChunkName: "component-latest-carosuel-editorial" */ './components/lastest/LatestCarouselEditorial'));
    registerComponent('latest-editorial', (attrs) => React.createElement(LatestCarouselEditorial, Object.assign({}, attrs)));
    const SalesRegistrationPage = React.lazy(() => import(/* webpackChunkName: "component-saleregistration" */ './components/sales/SalesRegistrationPage'));
    registerComponent('page-sales-registration', (attrs) => React.createElement(SalesRegistrationPage, Object.assign({}, attrs)));
    const ExpiredConfirmationProfileLinkModal = React.lazy(() => import(/* webpackChunkName: "component-account" */ './components/account/ExpiredConfirmationProfileLinkModal'));
    registerComponent('expired-confirmation-profile-link-modal', (attrs) => React.createElement(ExpiredConfirmationProfileLinkModal, Object.assign({}, attrs)));
    const NewsletterOptinConfirmModal = React.lazy(() => import(/* webpackChunkName: "component-newsletteroptin" */ './components/newsletter/NewsletterOptinConfirmModal'));
    registerComponent('newsletteroptin', (attrs) => React.createElement(NewsletterOptinConfirmModal, Object.assign({}, attrs)));
    const CountrySelectorPage = React.lazy(() => import(/* webpackChunkName: "component-countryselector-page" */ './components/countryselector/CountrySelectorPage'));
    registerComponent('countryselector-page', (attrs) => React.createElement(CountrySelectorPage, Object.assign({}, attrs)));
    const BookEventForm = React.lazy(() => import(/* webpackChunkName: "component-book-event-form" */ './components/common/BookEventForm'));
    registerComponent('book-event-form', (attrs) => React.createElement(BookEventForm, Object.assign({}, attrs)));
    const Lookbook = React.lazy(() => import(/* webpackChunkName: "component-lookbook" */ './components/lookbook/Lookbook'));
    registerComponent('lookbook', (attrs) => React.createElement(Lookbook, Object.assign({}, attrs)));
};
registerDefaultComponents();
registerStoneComponents();
initReactApps();
