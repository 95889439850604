/* eslint-disable indent */
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XhrBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
export const baseUrl = window.apiroot;
const checki18n = () => {
    return (i18n
        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
        debug: false,
        backend: {
            backends: window.devmode ? [XhrBackend] : [LocalStorageBackend, XhrBackend],
            backendOptions: window.devmode
                ? [
                    {
                        loadPath: `${baseUrl}/Translations-Bundle?locale={lng}`,
                    },
                ]
                : [
                    {
                        prefix: 'i18next_res_',
                        expirationTime: 30 * 60 * 1000, // 30 minutes
                    },
                    {
                        loadPath: `${baseUrl}/Translations-Bundle?locale={lng}`,
                    },
                ],
        },
        lng: document.documentElement.lang,
        fallbackLng: false,
        load: 'currentOnly',
        interpolation: {
            escapeValue: false,
            prefix: '{',
            suffix: '}',
        },
        cache: {
            // turn on or off
            enabled: true,
            // prefix for stored languages
            prefix: 'i18n_',
            // expiration
            expirationTime: 60 * 60 * 1000,
            // language versions
            //versions: {}
        },
    }, (error, t) => {
        console.log(error);
        console.log(t);
    }));
};
export const useLangChecker = (node) => {
    if (node) {
        const alternateLang = i18n.t(`untranslated.${node.textContent.replace(/[^a-zA-Z]+/g, '').toLowerCase()}`, '');
        if (alternateLang) {
            node.setAttribute('lang', alternateLang);
        }
    }
};
export default checki18n();
